import { ComponentChildren, Fragment, FunctionalComponent, h } from 'preact'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Button from './button'

interface ModalProps {
	children?: ComponentChildren
	title?: string
	open: boolean
	content?: string
	onClose: () => void
	confirmText?: string
	onConfirm: () => void
	showCancel?: boolean
	icon?: JSX.Element
	iconBg?: string
	iconSize?: string
	transparent?: boolean
	noButton?: boolean
	showClose?: boolean
	className?: string
}

const Modal: FunctionalComponent<ModalProps> = ({
	children,
	title,
	open,
	content,
	showCancel,
	onClose,
	confirmText,
	onConfirm,
	icon,
	iconBg,
	iconSize,
	transparent,
	noButton,
	showClose,
	className,
}: ModalProps) => {
	return (
		<div
			className={`flex items-end pb-6 justify-center transition duration-150 ease-in-out z-[1000000] fixed top-0 bottom-0 right-0 left-0 overflow-y-hidden ${
				open ? 'visible' : 'hidden'
			} ${transparent ? '' : 'bg-gray-500 bg-opacity-75'} ${className}`}
			id="modal"
		>
			<div
				role="alert"
				className="bg-white mx-4 rounded-lg  shadow-xl overflow-hidden transform transition-all align-middle w-screen max-w-lg"
			>
				{showClose && (
					<div
						onClick={onClose}
						className="bg-black grid justify-center items-center rounded-full m-1 p-1 float-right"
					>
						<XMarkIcon
							className="h-6 w-6 text-white font-bold  mx-auto"
							aria-hidden="true"
						/>
					</div>
				)}
				<div className="px-4 pt-4 pb-3 sm:p-6 sm:pb-4">
					<div className="items-start">
						{children ?? (
							<Fragment>
								<div
									className={`mx-auto flex-shrink-0 flex items-center justify-center rounded-full mb-3 ${
										iconBg ?? 'bg-yellow-50'
									} ${iconSize ?? 'h-12 w-12'}`}
								>
									{icon ?? (
										<ExclamationTriangleIcon
											className="h-6 w-6 text-yellow-600 items-center mx-auto"
											aria-hidden="true"
										/>
									)}
								</div>
								<div className="mt-5 text-center">
									<h3 className="text-lg leading-6 font-medium text-gray-900">
										{title}
									</h3>
									<div className="mt-2 text-center">
										<p className="mt-2 text-sm text-gray-500">{content}</p>
									</div>
								</div>
							</Fragment>
						)}
					</div>
				</div>
				{!noButton && (
					<div className="bg-gray-50 px-4 py-8 sm:px-6 sm:flex sm:flex-row-reverse">
						<Button
							className="py-3"
							title={confirmText ?? 'Ok'}
							onClick={() => onConfirm()}
						/>
						{showCancel && (
							<button
								type="button"
								className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
								onClick={() => onClose()}
							>
								Cancel
							</button>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

export default Modal
