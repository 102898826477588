export const getAudio = async (url: string) => {
	Audio.prototype.play = (function (play) {
		return function (this: any) {
			const audio = this as any,
				// eslint-disable-next-line prefer-rest-params
				args = arguments as any,
				promise = play.apply(audio, args)
			if (promise !== undefined) {
				promise.catch(() => {})
			}
		}
	})(Audio.prototype.play)

	const audio = new Audio(url)
	audio.preload = 'metadata'
	audio.crossOrigin = 'anonymous'

	await new Promise<void>(
		(resolve) => (audio.onloadedmetadata = () => resolve())
	)

	return audio
}

export function playAudio(audio: HTMLAudioElement) {
	audio.play()
}

export function stopAudio(audio: HTMLAudioElement) {
	audio.pause()
	audio.currentTime = 0
}
