import axios from 'axios'
import { getApiKey, getDomain } from '../utils'

// This is the base URL where requests comes from

export const http = ({ contentType = 'application/json' }) => {
	const http = axios.create({
		baseURL: process.env.API_URL,
		withCredentials: false,
		timeout: 90000,
		headers: {
			'Content-Type': contentType,
			// 'API-KEY': configStore.apiKey.value,
			// 'API-KEY': "5YQFAoqxBaauhHoWhEXsL-ynT97FELxayQuX-CXdCF8S3Pg=",
			// // DOMAIN: configStore.domain.value,
			
			// DOMAIN: "tlcsport.co.uk",
			'API-KEY': getApiKey(),
			DOMAIN: getDomain(),
		
		},
	})
	
	/*	
	 * The interceptor here ensures that we check for the token in the cookie every time an http request is made
	 */
	http.interceptors.request.use(
		(config) => {
			return config
		},
		(error) => {
			return Promise.reject(error)
		}
	)

	// Add a response interceptor
	http.interceptors.response.use(
		function (response) {
			return response
		},
		function (error) {
			return Promise.reject(error)
		}
	)
	return http
}

export default http
