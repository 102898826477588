class LocalStorage {
	set(key: string, value: any) {
		localStorage.setItem(key, JSON.stringify(value))
		return true
	}

	get(key: string) {
		const value = localStorage.getItem(key) as string
		try {
			return JSON.parse(value)
		} catch (e) {
			return null
		}
	}

	delete(name: string) {
		localStorage.removeItem(name)
	}
}

export default new LocalStorage()
