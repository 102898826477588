import { FunctionalComponent, h } from 'preact'

interface ErrorProps {
	title: string
}

const Error: FunctionalComponent<ErrorProps> = (props) => {
	const { title } = props

	return (
		<div className="bg-[#686d76] h-screen flex">
			<div className="flex my-auto mx-auto">
				<span className="text-white text-xl font-bold uppercase mr-3">
					{title}
				</span>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-7 w-7 text-red-500"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
					strokeWidth="2"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
					/>
				</svg>
			</div>
		</div>
	)
}

export default Error
