import { useEffect, useRef, useState } from 'preact/hooks'

export const useAnimatedText = (props: AnimatedTextInput[]) => {
	const intervalRef = useRef<NodeJS.Timeout | null>(null)

	const [currText, setCurrText] = useState(props[0].text)
	const [currSubText, setCurrSubText] = useState(props[0].subText)
	const [currIndex, setCurrIndex] = useState(0)

	useEffect(() => {
		intervalRef.current = setInterval(() => {
			const index = currIndex + 1
			clearInterval(intervalRef.current!)

			if (index < props.length) {
				setCurrIndex(index)
				setCurrText(props[index].text)
				setCurrSubText(props[index].subText)
				return
			}

			setCurrIndex(0)
			setCurrText(props[0].text)
			setCurrSubText(props[0].subText)
		}, props[currIndex].duration)
	}, [currText])

	return [currText, currSubText]
}
