import {
	ComponentChildren,
	Fragment,
	FunctionalComponent,
	h,
	VNode,
} from 'preact'
import Button from './button'

interface LayoutProps {
	title?: string | HTMLElement
	children: ComponentChildren
	onNext?: () => void
	onBack?: () => void
	multiple?: boolean
	fullLayout?: boolean
	hideHeader?: boolean
	noButton?: boolean
	onlyBack?: boolean
	backTitle?: string
	nextTitle?: string
	footer?: VNode
	bgColor?: string
}

const Layout: FunctionalComponent<LayoutProps> = (props: LayoutProps) => {
	const {
		title,
		children,
		multiple,
		fullLayout,
		hideHeader,
		onBack,
		onNext,
		noButton,
		onlyBack,
		backTitle,
		nextTitle,
		footer,
		bgColor,
	} = props

	return (
		<div className="dynamic-h overflow-hidden">
			{!hideHeader && (
				<div className="bg-white h-12 px-5 mb-2">
					<div className="text-gray-600 text-xs inline-block align-bottom pt-4 pb-3">
						| powered by AISTETIC
					</div>
				</div>
			)}

			<Fragment>
				<div className="h-full flex flex-col">
					<div
						className={`overflow-y-scroll overscroll-x-contain scroll-smooth scrollbar ${
							fullLayout ? '' : 'basis-4/5 py-2'
						}`}
					>
						<div
							className={`${bgColor ?? 'bg-white'} ${fullLayout ? '' : 'px-5'}`}
						>
							{title && (
								<h3 className="pb-4 text-2xl my-auto font-medium">{title}</h3>
							)}
							<div>{children}</div>
						</div>
					</div>
					<div>
						{noButton ? (
							<div>{footer}</div>
						) : (
							<div className="basis-1/5 mt-2 mx-4">
								{multiple ? (
									<div className="flex flex-row">
										<Button
											light
											title={''}
											className="py-3"
											titleWidget={
												<span className="cursor-pointer flex flex-row">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														strokeWidth={2}
														stroke="currentColor"
														className="w-5 h-5 mr-1"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M15.75 19.5L8.25 12l7.5-7.5"
														/>
													</svg>
													{backTitle ?? 'Back'}
												</span>
											}
											onClick={onBack}
										/>
										<Button
											className="py-3"
											title={nextTitle ?? 'next'}
											onClick={onNext}
										/>
									</div>
								) : (
									<div className="flex flex-row">
										<Button
											className="py-3"
											title={nextTitle ?? 'next'}
											onClick={onNext}
										/>
									</div>
								)}
							</div>
						)}
					</div>
					{onlyBack && (
						<Button
							light
							className="mt-3 mx-4 py-3"
							title={''}
							titleWidget={
								<span className="cursor-pointer flex flex-row">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={2}
										stroke="currentColor"
										className="w-5 h-5 mr-2"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M15.75 19.5L8.25 12l7.5-7.5"
										/>
									</svg>
									{backTitle ?? 'Back'}
								</span>
							}
							onClick={onBack}
						/>
					)}
				</div>
			</Fragment>
		</div>
	)
}

export default Layout
