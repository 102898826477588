import http from './http'

export async function uploadUserInfoService(
	formData: UploadUserInfoInput
): Promise<any> {
	return (await http({}).post('scan/user-info', formData)).data
}

/**
 * Process the pre upload reqeust
 */
export async function uploadCameraInfoService(
	formData: UploadCameraInfoInput
): Promise<UploadCameraInfoResponse> {
	return (
		await http({ contentType: 'multipart/form-data' }).post(
			'scan/cam-info',
			formData
		)
	).data
}

export async function getContentService(name: string): Promise<any> {
	return (await http({}).get(`merchant/get-content?name=${name}`)).data
}
