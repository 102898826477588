import http from './http'

export async function trackEventService(data: any): Promise<any> {
	return (await http({}).post('track', data)).data
}

export async function getIpService(): Promise<any> {
	const response = await fetch('https://api.ipify.org?format=json')
	return response.json()
}
