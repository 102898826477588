import { FunctionalComponent, h } from 'preact'
import Layout from '../components/layout'
import { useEffect, useState } from 'preact/hooks'
import Loader from '../components/loader'
import { isEmptyObj } from '../utils'
import { getContent } from '../actions/utility'

type FrameContainerProps = NavigationProps & {
	name: string
	title: string
}

const FrameContainer: FunctionalComponent<FrameContainerProps> = ({
	onBack,
	name,
	title,
}) => {
	const [content, setContent] = useState('')
	const [processing, setProcessing] = useState(true)

	useEffect(() => {
		getContent(name).then((res: string) => {
			setProcessing(false)
			if (isEmptyObj(res)) {
				setContent(
					`<div style="margin-top:80px; align-item:center; text-align:center;">Something went wrong</div>`
				)
				return
			}
			setContent(res)
		})
	}, [])

	return (
		<Layout title={''} noButton onlyBack onBack={onBack} backTitle="Back">
			<div>
				{processing ? (
					<Loader />
				) : (
					<div
						dangerouslySetInnerHTML={{
							__html: content,
						}}
					></div>
				)}
			</div>
		</Layout>
	)
}

export default FrameContainer
