import infoStore from '../store/infoStore'
import { CM_UNIT, FT_UNIT, IN_UNIT } from './config'

export const getUrlParam = (key: string) => {
	const urlParams = new URLSearchParams(
		decodeURIComponent(window.location.search)
	)
	return urlParams.get(key)
}

export const closeWindow = () => {
	var win = window.open('about:blank', '_self') as any
	win.close()
}

export function isEmpty(s: string): boolean {
	return s === '' || s === null || s === undefined
}

export function isiOS() {
	return /iPhone|iPad|iPod/i.test(navigator.userAgent)
}

export function isAndroid() {
	return /Android/i.test(navigator.userAgent)
}

export function isMobile() {
	return isAndroid() || isiOS()
}

export const clamp = (num: number, min: number, max: number) =>
	Math.min(Math.max(num, min), max)

export const getScanId = () => {
	return getUrlParam('token')!
}

export const getApiKey = () => {
	return getUrlParam('key')!
}

export const getDomain = () => {
	return getUrlParam('domain')!
}

export const getPlatform = () => {
	return getUrlParam('platform')!
}

export const getGender = () => {
	return getUrlParam('gender')!
}

export const getBrowserId = () => {
	return getUrlParam('browserId')!
}

export function isEmptyObj(obj: any): boolean {
	if (obj === undefined || obj === null || obj === '') return true
	return Object.entries(obj).length === 0 && obj.constructor === Object
}

export const validate = (value: string) => {
	return isEmpty(value)
}

export const validateInputRange = (value: string, range: number[]) => {
	const val = parseFloat(value)
	return val >= range[0] && val <= range[1]
}

export const getHeightUnitRange = (unit: string) => {
	return unit === CM_UNIT ? [150, 230] : [4.11, 7.7]
}

export const validateHeight = () => {
	let val = infoStore.height.value
	if (infoStore.heightUnit.value === FT_UNIT) {
		val = cmFtConversion(val, FT_UNIT).toString()
	}

	const isRangeValid = validateInputRange(
		val,
		getHeightUnitRange(infoStore.heightUnit.value)
	)
	infoStore.setIsHeightError(false)
	infoStore.setIsHeightRangeError(!isRangeValid)
}

export const cmFtConversion = (height: string, unit: string) => {
	const val = parseFloat(height)
	return unit === CM_UNIT ? val * 30.48 : val / 30.48
}

export const cmInConversion = (v: string, unit: string) => {
	const val = parseFloat(v)
	return unit === CM_UNIT ? val * 2.54 : val / 2.54
}

export const ftInConversion = (v: string, unit: string) => {
	const val = parseFloat(v)
	return unit === FT_UNIT ? val * 0.0833333 : val / 0.0833333
}

export const limitDecimalPlaces = (n: string, limits = 1) => {
	if (n === '') return n

	if (n.includes('.')) {
		const afterDot = n.substring(n.indexOf('.') + 1)
		if (afterDot.length > 1) {
			const nFloat = parseFloat(n)
			return nFloat.toFixed(limits)
		}
	}
	return n
}

export const preventDot = (e: any) => {
	return (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
}

export const limitInputLength = (e: any, limit = 1) => {
	return e.target.value.length >= limit && e.keyCode != 8 && e.preventDefault()
}

export const ftInchParsing = (n: string, unit: string) => {
	if (unit === IN_UNIT) {
		let afterDot = n.substring(n.indexOf('.') + 1)
		afterDot = '0.' + afterDot.substring(0, 2)
		return Math.round(parseFloat(afterDot) * 12)
	}
	return n.substring(0, n.indexOf('.'))
}

export const cmInchParsing = (n: string, unit: string) => {
	if (unit === IN_UNIT && n.includes('.')) {
		const afterDot = n.substring(n.indexOf('.') + 1)
		const beforeDot = n.substring(0, n.indexOf('.'))
		return beforeDot + "'" + afterDot + '"'
	}
	return n
}
