export const ASSET_URL =
	'https://aistetic-assets-tm6a4f1x7d.s3.eu-west-2.amazonaws.com'

// Height Unit
export const FT_UNIT = 'ft'
export const CM_UNIT = 'cm'
export const IN_UNIT = 'in'

// Gender
export const FEMALE_GENDER = 'female'
export const MALE_GENDER = 'male'
export const NON_BINARY_GENDER = 'non_binary'

// Storage
export const INFO_STORAGE = 'aistetic_info'
export const FIT_PREFERENCE_STORAGE = 'aistetic_fit_preference'

export enum RECOMMENDATION_MODE {
	QUICK = 'quick',
	SCAN = 'scan',
	QUICK_AND_SCAN = 'quick_and_scan',
}

// Fitting
export const TIGHT_FITTING = 'tight'
export const REGULAR_FITTING = 'regular'
export const LOOSE_FITTING = 'loose'

// Fit Preference Section
export const TOP_SECTION = 'top'
export const BOTTOM_SECTION = 'bottom'
