import { deepSignal } from '@deepsignal/preact'
import { AppConfig } from 'types/configuration'

const configStore = {
	...deepSignal({
		loading: true as boolean,
		ip: '',
		data: '' as AppConfig,
	}),

	setLoading(status: boolean) {
		this.loading.value = status
	},
	setData(data: AppConfig) {
		this.data.value = data
	},
	setIp(ip: string) {
		this.ip.value = ip
	},
	getMeasurementRoute() {
		return this.data.value.defaults?.routes.measurement_route!
	},
	getAll() {
		return {}
	},
}

export default configStore
