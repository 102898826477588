import { uploadCameraInfoService, uploadUserInfoService } from '../services/scan'

export const uploadUserInfo = async (input: UploadUserInfoInput) => {
	return uploadUserInfoService(input)
		.then((response: UploadUserInfoResponse) => {
			return response.message
		})
		.catch((err) => {
			console.log(err)
		})
		.finally(() => { }) as Promise<string>
}

export const uploadCameraInfo = async (input: UploadCameraInfoInput) => {
	return uploadCameraInfoService(input)
		.then((response: UploadCameraInfoResponse) => {
			return response
		})
		.catch((err) => {
			console.log(err)
		})
		.finally(() => { }) as Promise<UploadCameraInfoResponse>
}
