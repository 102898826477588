export default class Vector {
	x: number
	y: number
	z: number

	constructor(mX: number, mY: number, mZ: number) {
		this.x = mX
		this.y = mY
		this.z = mZ
	}

	magnitude = (): number => {
		return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z)
	}

	divide = (scalar: number): Vector => {
		return new Vector(this.x / scalar, this.y / scalar, this.z / scalar)
	}

	normalise = (): Vector => {
		return this.divide(this.magnitude())
	}

	flatten = (): number[] => {
		const normalized = this.normalise()
		return [normalized.x, normalized.y, normalized.z]
	}
}
