import { FunctionalComponent, h } from 'preact'
import Layout from '../components/layout'
import {
	ASSET_URL,
	CM_UNIT,
	FEMALE_GENDER,
	FT_UNIT,
	MALE_GENDER,
	NON_BINARY_GENDER,
} from '../utils/config'
import { useEffect, useState } from 'preact/hooks'
import { track } from '../utils/tracker'
import { useIsGender } from '../hooks/useIsGender'
import { useIsName } from '../hooks/useIsName'
import infoStore from '../store/infoStore'
import configStore from '../store/configStore'
import {
	getScanId,
	isEmpty,
	limitDecimalPlaces,
	limitInputLength,
	preventDot,
	validate,
	validateHeight,
} from '../utils'
import {
	COOKIE_POLICY_ROUTE,
	PRIVACY_POLICY_ROUTE,
	TERMS_AND_CONDITIONS_ROUTE,
} from '../routes'
import { useIsFirstName } from '../hooks/useIsFirstName'
import { useIsLastName } from '../hooks/useIsLastName'

type InfoProps = NavigationProps & {
	navigateToView: (view: string) => void
}

const Info: FunctionalComponent<InfoProps> = ({
	navigateToView,
	onNext,
}: InfoProps) => {
	const genderField = useIsGender()
	const firstNameField = useIsFirstName()
	const lastNameField = useIsLastName()
	const nameField = useIsName()

	const [showError, setShowError] = useState(false)

	const selectedGenderStyle =
		'rounded-xl border-[2px] border-default-500 px-1 py-1'
	const selectedUnitStyle =
		'transition delay-50 duration-75 ease-in bg-white rounded-lg'

	useEffect(() => {
		track('scan_1_open')
	}, [])

	return (
		<Layout
			title="Tell us about yourself"
			onBack={() => { }}
			nextTitle="Continue"
			onNext={() => {
				if (firstNameField?.show) {
					infoStore.setIsFirstNameError(validate(infoStore.firstName.value))
				}

				if (lastNameField?.show) {
					infoStore.setIsLastNameError(validate(infoStore.lastName.value))
				}

				if (nameField?.show) {
					infoStore.setIsNameError(validate(infoStore.name.value))
				} else {
					infoStore.setName(getScanId())
				}

				if (firstNameField?.show && lastNameField?.show) {
					infoStore.setName(
						infoStore.firstName.value + ' ' + infoStore.lastName.value
					)
				}

				if (genderField?.show) {
					infoStore.setIsGenderError(validate(infoStore.gender.value))
				} else {
					infoStore.setGender(configStore.data.value.defaults?.gender!)
				}

				infoStore.setIsHeightError(validate(infoStore.height.value))
				if (infoStore.height.value !== '') {
					validateHeight()
				}

				if (!infoStore.acceptedTerms.value) {
					setShowError(true)
					return
				}

				if (
					!infoStore.isFirstNameError.value &&
					!infoStore.isLastNameError.value &&
					!infoStore.isNameError.value &&
					!infoStore.isGenderError.value &&
					!isEmpty(infoStore.height.value) &&
					!infoStore.isHeightRangeError.value
				) {

					track('scan_3_1_intro')
					onNext!()
				}
			}}
		>
			<div>
				{firstNameField?.show && (
					<div className="my-2">
						<h6 className="text-xs text-gray-400">First name</h6>
						<input
							value={infoStore.firstName.value}
							onChange={(e: any) => infoStore.setFirstName(e.target!.value)}
							class="w-full border-[1px] border-gray-200 focus:outline-none px-3 rounded-md mt-2 py-3"
						/>
						{infoStore.isFirstNameError.value && (
							<span className="text-red-500 text-[11px]">
								First name is required
							</span>
						)}
					</div>
				)}
				{lastNameField?.show && (
					<div className="my-2">
						<h6 className="text-xs text-gray-400">Last name</h6>
						<input
							value={infoStore.lastName.value}
							onChange={(e: any) => infoStore.setLastName(e.target!.value)}
							class="w-full border-[1px] border-gray-200 focus:outline-none px-3 rounded-md mt-2 py-3"
						/>
						{infoStore.isLastNameError.value && (
							<span className="text-red-500 text-[11px]">
								Last name is required
							</span>
						)}
					</div>
				)}
				{nameField!.show && (
					<div className="my-2">
						<h6 className="text-xs text-gray-400">Your Name</h6>
						<input
							value={infoStore.name.value}
							onChange={(e: any) => infoStore.setName(e.target!.value)}
							class="w-full border-[1px] border-gray-200 focus:outline-none px-3 rounded-md mt-2 py-3"
						/>
						{infoStore.isNameError.value && (
							<span className="text-red-500 text-[11px]">Name is required</span>
						)}
					</div>
				)}
				{genderField!.show && (
					<div className="mt-5">
						<p className="text-xs text-gray-400">
							Don't worry this information will not be shared and will be erased
							when you reset your cookies
						</p>
						<div className="grid grid-cols-3 gap-4 mt-6 mb-2">
							<img
								onClick={() => infoStore.setGender(FEMALE_GENDER)}
								className={`mx-auto cursor-pointer pt-1 px-1 shadow-lg rounded-lg ${infoStore.gender.value === FEMALE_GENDER
									? selectedGenderStyle
									: ''
									}`}
								src={`${ASSET_URL}/female.svg`}
							/>
							<img
								onClick={() => infoStore.setGender(MALE_GENDER)}
								className={`mx-auto cursor-pointer pt-1 px-1 shadow-lg rounded-lg ${infoStore.gender.value === MALE_GENDER
									? selectedGenderStyle
									: ''
									}`}
								src={`${ASSET_URL}/male.svg`}
							/>
							<img
								onClick={() => infoStore.setGender(NON_BINARY_GENDER)}
								className={`mx-auto cursor-pointer pt-1 px-1 shadow-lg rounded-lg ${infoStore.gender.value === NON_BINARY_GENDER
									? selectedGenderStyle
									: ''
									}`}
								src={`${ASSET_URL}/non-binary.svg`}
							/>
						</div>
						{infoStore.isGenderError.value && (
							<span className="text-red-500 text-[11px]">
								Please, choose one from above
							</span>
						)}
					</div>
				)}

				<div className="my-5">
					<p className="text-xs text-gray-400">Your height</p>
					<div className="flex mt-2 gap-6 h-10">
						<div className="grid grid-cols-2 gap-1 p-0.5 rounded-lg bg-gray-100 text-xs">
							<div
								onClick={() => infoStore.setHeightUnit(CM_UNIT)}
								className={`grid px-2 cursor-pointer ${infoStore.heightUnit.value === CM_UNIT
									? selectedUnitStyle
									: ''
									}`}
							>
								<div className="mx-auto my-auto">Cm</div>
							</div>

							<div
								onClick={() => infoStore.setHeightUnit(FT_UNIT)}
								className={`grid px-2 cursor-pointer ${infoStore.heightUnit.value === FT_UNIT
									? selectedUnitStyle
									: ''
									}`}
							>
								<div className="mx-auto my-auto">Ft</div>
							</div>
						</div>
						<div>
							{infoStore.heightUnit.value === CM_UNIT ? (
								<label
									htmlFor="height"
									className="relative text-gray-500 focus-within:text-gray-600 flex"
								>
									<input
										id="height_cm"
										type="number"
										defaultValue={limitDecimalPlaces(infoStore.height.value)}
										onChange={(e: any) => infoStore.setHeight(e.target!.value)}
										onKeyDown={(e) => {
											limitInputLength(e, 4)
										}}
										autoComplete="off"
										className="border-[1px] border-gray-200 focus:outline-none px-3 py-2 rounded-lg placeholder-gray-300"
										placeholder="170"
									/>

									<p className="pointer-events-none absolute top-1/2 transform -translate-y-1/2 right-3">
										{infoStore.heightUnit.value}
									</p>
								</label>
							) : (
								<div className="flex gap-1">
									<label
										htmlFor="height"
										className="relative text-gray-500 focus-within:text-gray-600 flex"
									>
										<input
											id="height_ft"
											type="number"
											value={infoStore.heightFt.value}
											onInput={(e: any) =>
												infoStore.setHeightFt(e.target!.value)
											}
											onKeyDown={(e) => {
												limitInputLength(e)
												preventDot(e)
											}}
											autoComplete="off"
											className="border-[1px] border-gray-200 focus:outline-none px-3 py-2 rounded-lg placeholder-gray-300 w-24 mx-1"
											placeholder="6"
										/>
										<p className="pointer-events-none absolute top-1/2 transform -translate-y-1/2 right-3">
											ft
										</p>
									</label>
									<label
										htmlFor="height"
										className="relative text-gray-500 focus-within:text-gray-600 flex"
									>
										<input
											id="height_in"
											type="number"
											value={infoStore.heightInch.value}
											onInput={(e: any) =>
												infoStore.setHeightInch(e.target!.value)
											}
											onKeyDown={(e) => {
												limitInputLength(e, 2)
												preventDot(e)
											}}
											autoComplete="off"
											className="border-[1px] border-gray-200 focus:outline-none px-3 py-2 rounded-lg placeholder-gray-300 w-24 mx-1"
											placeholder="2"
										/>
										<p className="pointer-events-none absolute top-1/2 transform -translate-y-1/2 right-3">
											in
										</p>
									</label>
								</div>
							)}

							{infoStore.isHeightError.value && (
								<span className="text-red-500 text-[11px]">
									Height is required
								</span>
							)}
							{infoStore.isHeightRangeError.value && (
								<span className="text-red-500 text-[11px]">
									Height must be between
									{infoStore.heightUnit.value === CM_UNIT
										? '150cm and 230cm'
										: `4'11" and 7'7"`}
								</span>
							)}
						</div>
					</div>
				</div>

				<div className="mt-14">
					<div className="flex mt-2 flex-row">
						<div class="relative inline-block w-7 mr-2 align-middle select-none transition duration-50 ease-in mt-2">
							<input
								type="checkbox"
								checked={infoStore.acceptedTerms.value}
								onChange={() => {
									infoStore.setAcceptedTerms(!infoStore.acceptedTerms.value)
									setShowError(!infoStore.acceptedTerms.value)
								}}
								name="toggle"
								id="toggle"
								class="toggle-checkbox absolute block w-4 h-4 rounded-full bg-white border-2 appearance-none cursor-pointer"
							/>
							<label
								for="toggle"
								class="toggle-label block overflow-hidden h-4 rounded-full bg-gray-300 cursor-pointer"
							></label>
						</div>
						<div class="text-xs basis-5/6 text-gray-500">
							<label for="toggle">
								In giving my agreement here, I accept Aistetic's
							</label>
							<span className="text-gray-600">
								<span
									className="mx-1 underline cursor-pointer "
									onClick={() => navigateToView(TERMS_AND_CONDITIONS_ROUTE)}
								>
									
									Terms & Conditions,
								</span>
								<span
									className="underline cursor-pointer mx-1"
									onClick={() => {
										track('scan_2_privacy')
										navigateToView(PRIVACY_POLICY_ROUTE)}
								
								}
								>
									Privacy Notice
								</span>
								and
								<span
									className="mx-1 underline cursor-pointer"
									onClick={() => navigateToView(COOKIE_POLICY_ROUTE)}
								>
									Cookie Notice.
								</span>
							</span>
						</div>
					</div>
					{showError && (
						<span className=" text-[11px] mx-auto text-red-500 text-center">
							Please accept terms & conditions
						</span>
					)}
				</div>
			</div>
		</Layout>
	)
}

export default Info
