import { FunctionalComponent, h } from 'preact'
import Loader from './loader'

interface PageLoaderProps {
	open: boolean
}

const PageLoader: FunctionalComponent<PageLoaderProps> = ({
	open,
}: PageLoaderProps) => {
	return (
		<div
			className={`flex items-center justify-center bg-gray-500 bg-opacity-75 transition duration-150 ease-in-out z-[1000000] fixed top-0 bottom-0 right-0 left-0 overflow-y-hidden ${
				open ? 'visible' : 'hidden'
			}`}
			id="PageLoader"
		>
			<div
				role="alert"
				className="bg-white mx-4 inline-block rounded-lg relative shadow-xl overflow-hidden transform transition-all align-middle w-screen max-w-lg"
			>
				<div className="w-full flex  justify-center text-gray-600 mb-3">
					<Loader className="py-3 pb-2 px-4" title="Uploading video..." />
				</div>
			</div>
		</div>
	)
}

export default PageLoader
