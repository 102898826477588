import { deepSignal } from '@deepsignal/preact'
import { effect } from '@preact/signals'
import { CM_UNIT, FT_UNIT, INFO_STORAGE, IN_UNIT } from '../utils/config'
import {
	cmFtConversion,
	cmInConversion,
	ftInchParsing,
	ftInConversion,
	validate,
	validateHeight,
} from '../utils'
import storage from '../utils/storage'

const getInitialInfoStore = (): InfoStorage => {
	const info = storage.get(INFO_STORAGE)
	return {
		firstName: info?.firstName ?? '',
		lastName: info?.lastName ?? '',
		name: info?.name ?? '',
		gender: info?.gender ?? '',
		height: info?.height ?? '',
		heightFt: info?.heightFt ?? '',
		heightInch: info?.heightInch ?? '',
		heightUnit: info?.heightUnit ?? CM_UNIT,
		acceptedTerms: info?.acceptedTerms ?? false,
	}
}

const infoStore = {
	...deepSignal({
		...getInitialInfoStore(),
		...{
			isFirstNameError: false as boolean,
			isLastNameError: false as boolean,
			isNameError: false as boolean,
			isGenderError: false as boolean,
			isHeightError: false as boolean,
			isHeightRangeError: false as boolean,
		},
	}),
	setFirstName(firstName: string) {
		this.firstName.value = firstName
		this.setIsFirstNameError(validate(firstName))
	},
	setIsFirstNameError(val: boolean) {
		this.isFirstNameError.value = val
	},
	setLastName(lastName: string) {
		this.lastName.value = lastName
		this.setIsLastNameError(validate(lastName))
	},
	setIsLastNameError(val: boolean) {
		this.isLastNameError.value = val
	},
	setName(name: string) {
		this.name.value = name
		this.setIsNameError(validate(name))
	},
	setIsNameError(val: boolean) {
		this.isNameError.value = val
	},
	setGender(gender: string) {
		this.gender.value = gender
		this.setIsGenderError(false)
	},
	setIsGenderError(val: boolean) {
		this.isGenderError.value = val
	},
	setHeight(height: string) {
		if (height === '') {
			this.setIsHeightError(true)
			this.setIsHeightRangeError(false)
			return
		}

		this.height.value = height
		if (this.heightUnit.value === CM_UNIT) {
			const ftEqui = cmFtConversion(height, FT_UNIT).toString()
			this.heightFt.value = ftInchParsing(ftEqui, FT_UNIT).toString()
			this.heightInch.value = ftInchParsing(ftEqui, IN_UNIT).toString()
		}

		validateHeight()
	},
	setHeightFt(val: string) {
		this.heightFt.value = val.replaceAll('.', '').slice(0, 2)
		if (val !== '' && this.heightInch.value !== '') {
			val = (
				parseFloat(val) + ftInConversion(this.heightInch.value, FT_UNIT)
			).toString()
		}

		this.height.value = cmFtConversion(val, CM_UNIT).toString()
		validateHeight()
	},
	setHeightInch(val: string) {
		this.heightInch.value = val.replaceAll('.', '')
		if (val !== '' && this.heightFt.value !== '') {
			val = (
				parseFloat(val) + ftInConversion(this.heightFt.value, IN_UNIT)
			).toString()
		}

		this.height.value = cmInConversion(val, CM_UNIT).toString()
		validateHeight()
	},
	setIsHeightError(val: boolean) {
		this.isHeightError.value = val
	},
	setIsHeightRangeError(val: boolean) {
		this.isHeightRangeError.value = val
	},
	setHeightUnit(unit: string) {
		this.heightUnit.value = unit
	},
	setAcceptedTerms(terms: boolean) {
		this.acceptedTerms.value = terms
	},

	getAll() {
		return {
			firstName: this.firstName.value,
			lastName: this.lastName.value,
			name: this.name.value,
			gender: this.gender.value,
			height: this.height.value,
			heightFt: this.heightFt.value,
			heightInch: this.heightInch.value,
			heightUnit: this.heightUnit.value,
			acceptedTerms: this.acceptedTerms.value,
		}
	},
}

effect(() => storage.set(INFO_STORAGE, infoStore.getAll()))

export default infoStore
