const navigator = window.navigator as any

navigator.getUserMedia =
	navigator.getUserMedia ||
	navigator.mozGetUserMedia ||
	navigator.webkitGetUserMedia

export function captureUserMedia(
	callback: (stream: MediaStream) => void,
	error: (err: any) => void
) {
	var params: MediaStreamConstraints = {
		audio: false,
		video: {
			facingMode: 'user',
			width: { min: 640, ideal: 640 },
			height: { min: 360, ideal: 360 },
		},
	}

	navigator.mediaDevices.getUserMedia(params).then(callback).catch(error)
}

export function getMimeType() {
	const isMp4 = MediaRecorder.isTypeSupported('video/mp4')

	let mimeType = 'video/webm'
	if (isMp4) {
		mimeType = 'video/mp4'
	}

	return mimeType
}

export function isVideoEnabled() {
	const navigator = window.navigator as any

	return !!(
		navigator.mediaDevices ||
		navigator.mediaDevices.getUserMedia ||
		navigator.getUserMedia ||
		navigator.webkitGetUserMedia ||
		navigator.mozGetUserMedia ||
		navigator.msGetUserMedia
	)
}
