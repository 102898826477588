import { FunctionalComponent, h, Fragment } from 'preact'
import { useEffect, useRef } from 'preact/hooks'
import { useState } from 'preact/hooks'

import {
	BASE_ROUTE,
	COOKIE_POLICY_ROUTE,
	PRIVACY_POLICY_ROUTE,
	RECORD_VIDEO_ROUTE,
	TERMS_AND_CONDITIONS_ROUTE,
	VIDEO_UPLOADED_ROUTE,
} from './routes'
import { getAudio, playAudio, stopAudio } from './utils/audio'
import Loader from '../src/components/loader'
import Layout from '../src/components/layout'
import RecordVideo from '../src/views/recordVideo'
import { closeWindow, isMobile } from '../src/utils'
import Error from '../src/components/error'
import Info from '../src/views/info'
import { InitializeProvider } from '../src/providers/initializeProvider'
import configStore from '../src/store/configStore'
import FrameContainer from '../src/views/frameContainer'
import VideoUploaded from '../src/views/videoUploaded'

const App: FunctionalComponent = (props) => {
	const setViewHeight: any = () => {
		const vh = window.innerHeight
		document.documentElement.style.setProperty('--vh', `${vh * 0.01}px`)
		document.documentElement.style.setProperty('--dh', `${vh}px`)
	}

	const [page, setPage] = useState(BASE_ROUTE)
	const [isPortrait, setIsPortrait] = useState(true)
	const [loadedAssets, setLoadedAssets] = useState(false)

	const skinTightLongHairAudioRef = useRef<HTMLAudioElement | null>(null)
	const simpleBackgroundAudioRef = useRef<HTMLAudioElement | null>(null)
	const tiltPhoneAudioRef = useRef<HTMLAudioElement | null>(null)
	
	const stepBackAudioRef = useRef<HTMLAudioElement | null>(null)
	const beepAudioRef = useRef<HTMLAudioElement | null>(null)
	const recordingAudioRef = useRef<HTMLAudioElement | null>(null)
	const finishedAudioRef = useRef<HTMLAudioElement | null>(null)

	useEffect(() => {
		setViewHeight()
		handleLandscape()

		window.addEventListener('resize', () => {
			setViewHeight()
			handleLandscape()
		})
	}, [])

	const handleLandscape = () => {
		if (isMobile()) {
			if (window.innerWidth > window.innerHeight) {
				setIsPortrait(false)
			} else {
				setIsPortrait(true)
			}
		}
	}

	const getView: FunctionalComponent = () => {
		switch (page) {
			case BASE_ROUTE:
				return (
					<Info
						onMount={() => { }}
						onUnMount={() => { }}
						navigateToView={(view: string) => {
							setPage(view)
						}}
						onNext={() => {
							if (loadedAssets) {
								setPage(RECORD_VIDEO_ROUTE)
								return
							}

							configStore.setLoading(true)

							Promise.all([
								getAudio('assets/audio/skin-tight-long-hair.mp3'),
								getAudio('assets/audio/simple-background.mp3'),
								getAudio('assets/audio/tilt-phone.mp3'),
								getAudio('assets/audio/step-back.mp3'),
								getAudio('assets/audio/beep.mp3'),
								getAudio('assets/audio/recording.mp3'),
								getAudio('assets/audio/finished.m4a'),
								getAudio('assets/audio/lean-your-phone-v1_Audio-Denoise.mp3')
							]).then((result: any) => {
								skinTightLongHairAudioRef.current = result[0]
								simpleBackgroundAudioRef.current = result[1]
								tiltPhoneAudioRef.current = result[2]

								stepBackAudioRef.current = result[3]
								beepAudioRef.current = result[4]
								recordingAudioRef.current = result[5]
								finishedAudioRef.current = result[6]
								
								configStore.setLoading(false)
								setLoadedAssets(true)
								setPage(RECORD_VIDEO_ROUTE)
							})
						}}
					/>
				)
			case PRIVACY_POLICY_ROUTE:
				return (
					<FrameContainer
						name="privacy_policy"
						title="Aistetic Privacy Policy"
						onBack={() => setPage(BASE_ROUTE)}
					/>
				)
			case TERMS_AND_CONDITIONS_ROUTE:
				return (
					<FrameContainer
						name="terms_and_conditions"
						title="Aistetic Terms and Conditions"
						onBack={() => setPage(BASE_ROUTE)}
					/>
				)
			case COOKIE_POLICY_ROUTE:
				return (
					<FrameContainer
						name="cookie_policy"
						title="Aistetic Cookie Policy"
						onBack={() => setPage(BASE_ROUTE)}
					/>
				)
			case RECORD_VIDEO_ROUTE:
				return (
					<RecordVideo
						onMount={() => { }}
						onUnMount={() => { }}
						playSkinTightLongHairAudio={() =>
							playAudio(skinTightLongHairAudioRef.current!)
						}
						stopSkinTightLongHairAudio={() =>
							stopAudio(skinTightLongHairAudioRef.current!)
						}
						playSimpleBackgroundAudio={() =>
							playAudio(simpleBackgroundAudioRef.current!)
						}
						stopSimpleBackgroundAudio={() =>
							stopAudio(simpleBackgroundAudioRef.current!)
						}

						

						playTiltPhoneAudio={() => playAudio(tiltPhoneAudioRef.current!)}
						

						stopTiltPhoneAudio={() => stopAudio(tiltPhoneAudioRef.current!)}
						
						playStepBackAudio={() => playAudio(stepBackAudioRef.current!)}
						stopStepBackAudio={() => stopAudio(stepBackAudioRef.current!)}
						playBeepAudio={() => playAudio(beepAudioRef.current!)}
						stopBeepAudio={() => stopAudio(beepAudioRef.current!)}
						playRecordingAudio={() => playAudio(recordingAudioRef.current!)}
						stopRecordingAudio={() => stopAudio(recordingAudioRef.current!)}
						playFinishedAudio={() => playAudio(finishedAudioRef.current!)}
						onBack={() => setPage(BASE_ROUTE)}
						onNext={() => {
							stopAudio(finishedAudioRef.current!)
							setPage(VIDEO_UPLOADED_ROUTE)
						}}
					/>
				)
			case VIDEO_UPLOADED_ROUTE:
				return (
					<VideoUploaded
						onMount={() => { }}
						onUnMount={() => { }}
						onBack={() => setPage(RECORD_VIDEO_ROUTE)}
						onNext={() => {
							closeWindow()
						}}
					/>
				)

			default:
				return <Fragment />
		}
	}

	return (
		<InitializeProvider>
			<Fragment>
				{configStore.loading.value ? (
					<Layout title="" noButton>
						<div className="mt-10">
							<Loader />
						</div>
					</Layout>
				) : (
					<Fragment>
						{isPortrait ? (
							getView(props)
						) : (
							<Error title="Landscape mode not allowed" />
						)}
					</Fragment>
				)}
			</Fragment>
		</InitializeProvider>
	)
}

export default App
