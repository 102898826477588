import { FunctionalComponent, h, VNode } from 'preact'

interface ButtonProps {
	onClick?: () => void
	title: string
	titleWidget?: VNode
	light?: boolean
	className?: string
}

const Button: FunctionalComponent<ButtonProps> = ({
	title,
	onClick,
	light,
	className,
	titleWidget,
}: ButtonProps) => {
	return (
		<button
			onClick={onClick}
			className={`w-full rounded-md text-sm uppercase ${
				light ? 'bg-white text-gray-600 text-left' : 'bg-default-500 text-white'
			} ${className} `}
		>
			{titleWidget ?? title}
		</button>
	)
}

export default Button
